/** @jsx jsx */
import { jsx, Box, Container, Flex, Styled, Text, AspectRatio } from "theme-ui"
import { graphql, Link  } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  AuthorCard,
  BlogHero,
} from "../components/elements"

const Post = ({ data, pageContext }) => {
  const page = data.datoCmsPost
  
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      {page?.seoMetaTags &&
        <HelmetDatoCms seo={page?.seoMetaTags} />
      }  

      <Main>
        <BlogHero />
           
        {/* Content */}
        <Section id="content" className="post">
          <Container sx={{ px: "1em" }}>
            <Flex
              sx={{
                width: ["full"],
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >

              <Box sx={{ width: ["full", "4/5", "2/3"] }}>

                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page?.title}
                  sx={{ color: 'black' }}
                />

                <Box as="p" sx={{ mb:[3]}}>
                  <Box as="span" sx={{ fontWeight: 'bold'}}>Hope & Health</Box><br />
                  Articles and Updates from WVU Medicine Children&apos;s
                </Box>
                
                <Box as="p" sx={{ fontWeight: 'bold' }}>{page?.date}{page?.author?.name && <span>{` | ${page?.author?.name}`}</span>}</Box>
                
                {/* {page?.tags.length > 0 &&
                  <Box as="ul" sx={{ m:0, p: 0, listStyle: 'none', display: 'flex', flexWrap: 'wrap'}}>
                    {page?.tags?.map(({ ...tag }) => (
                      <Box as="li" sx={{mr: 2}}><Link to={`/blog/tag/${tag?.slug}`}>{tag?.title}</Link></Box>
                    ))}
                  </Box>
                } */}
                  <h1 sx={{ mt: [3,3,4], lineHeight: 1}}>{page?.title}</h1>
                {page?.blogImage &&
                  <AspectRatio ratio={4 / 3}>
                    <Img
                      fluid={page?.blogImage?.fluid}
                      alt={page?.title || 'post image'}
                      sx={{ width: "full", my: 4, objectFit: 'contain', objectPosition: 'center' }}
                      loading="lazy"
                    />
                  </AspectRatio>
                }

                <Text
                  sx={{ width: ["full"] }}
                  dangerouslySetInnerHTML={{
                    __html: page?.contentNode?.childMarkdownRemark.html,
                  }}
                />

              </Box>
            </Flex>
          </Container>
        </Section>

        {page?.author  &&
          <Box sx={{ width: 'full', bg: "warmgray", py: 4 }}>
            <Container sx={{ px: "1em" }}>
              
              <AuthorCard author={page?.author} />
            
              <Box sx={{ w: "full", mt: 3, textAlign: 'right' }}><Link to={`/blog/author/${page?.author?.slug}`}>See all blogs by {page?.author?.name}</Link></Box>
            
            </Container>
          </Box>
        }
      </Main>
    </Layout>
  )
}

export default Post

export const query = graphql`
  query postPageQuery($slug: String!) {
    datoCmsPost(
      slug: { eq: $slug }
      meta: { status: { eq: "published" } }
    ) {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      id
      date(formatString: "MM/d/yyyy")
      blogImage {
          fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      meta {
        status
      }
      slug
      title
      author {
        name
        slug
        id
        bioNode {
          childMarkdownRemark {
            html
          }
        }
        portrait {
          fluid(maxWidth: 400, imgixParams: { fm: "jpg", auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
        }
    }
      tags {
      ... on DatoCmsUnit {
        id
        title
        slug
      }
      ... on DatoCmsProgram {
        id
        title
        slug
      }
      ... on DatoCmsCenterOfExcellence {
        id
        title
        slug
      }
      ... on DatoCmsChildrensService {
        id
        title
        slug
      }
      ... on DatoCmsChildrensProgram {
        id
        slug
        title
      }
    }
      
    }
    
  }
`
